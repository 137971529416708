/* eslint-disable max-len */
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { IUser } from '../types/AccessTypes';

export const toFirstUpperCase = (str: string | undefined) => (str ? str[0].toUpperCase() + str.slice(1) : '');

export const removeNullString = (str: string | null) => (str === null ? '' : str.trim());
export const arrayOfStringToNewLineString = (arr: string[] | undefined) => (arr ? arr.reduce((prev, str) => `${prev}${prev && '\n'}${removeNullString(str)}`, '') : '');
export const arrayToCSVString = (arr: string[] | undefined) => (arr && Array.isArray(arr) ? arr.reduce((prev, str) => `${prev}${prev && ', '}${removeNullString(str)}`, '') : '');

/**
 * Convert the newline separated string to an array, optionally filtering through the filter.
 * @param input The newline separated input string.
 * @param filter An optional filter for filtering the array elements.
 * @returns An array with lines matching the filter.
 */
export const stringToArray = (input: string, filter: ((arg0:string)=>boolean)|undefined = undefined) => input
  .split('\n')
  .map((s) => s.trim())
  .filter((s) => (filter ? filter(s) : s));

export const stringToCSVString = (str: string) => str.split('\n').reduce((prev, cur, i) => (cur ? `${prev}${i === 0 ? '' : ','}${cur.trim()}` : prev), '');
export const csvStringToNewLineSeparated = (str: string | null | undefined, separator = ',') => (str ? arrayOfStringToNewLineString(str.split(separator)) : '');
export const csvStringToArray = (str: string | null | undefined, separator = ',') => (str ? str.split(separator).map((s) => s.trim()) : []);

export const boolToYesNo = (value: boolean | undefined) => (value ? 'Yes' : 'No');

/**
 * Check if a string is a valid email address. The check is very basic and just check the
 * basic structure of the email. Email validation is hard, but we need some simple ways to
 * remove crap from the textareas that allow newline separated emails input.
 *
 * @param input A string that might contain an email address.
 * @returns True if the string contains an email address, false if not.
 */
export const filterEmails = (input: string) => Boolean(input.match(/^.+@.+\..+$/));

export const filterGuid = (input: string) => Boolean(
  input.match(/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i),
);

const defaultDateOptions : Intl.DateTimeFormatOptions = {
  dateStyle: 'short',
  timeStyle: 'short',
};

export const isFutureDate = (inputDate:string|Date, now?:Date|undefined) => {
  const date = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
  return date >= (now ?? new Date());
};

export const insertNewlineMarkers = (
  input:(() => string)|string,
  splitCharacters?:RegExp,
) => {
  const inputString = typeof input === 'function' ? input() : input;
  return inputString.split(splitCharacters ?? /(?=\/| |-|_|:)/).join('&#8203;');
};

export const getStringDate = (
  date: string | Date | undefined | null,
  options?:Intl.DateTimeFormatOptions,
  locale?:string,
) => {
  if (!date) return ''; // Return None?
  if (typeof date === 'string') {
    const converted = new Date(date);
    if (Number.isNaN(converted.getTime())) return date;
    return converted.toLocaleString(
      locale ?? window?.navigator?.language ?? 'nb-NO',
      options ? { ...defaultDateOptions, ...options } : defaultDateOptions,
    );
  }
  return date.toLocaleString(
    locale ?? window?.navigator?.language ?? 'nb-NO',
    options ? { ...defaultDateOptions, ...options } : defaultDateOptions,
  );
};

export const getStringDateOnly = (date: string | Date | undefined | null) => {
  if (!date) return '';
  if (typeof date === 'string') {
    const converted = new Date(date);
    if (Number.isNaN(converted.getTime())) return date;
    return converted.toLocaleDateString();
  }
  return date.toLocaleDateString();
};

export const getUTCStringDateOnly = (date:string|Date) => {
  const converted = new Date(date);
  const timeDiff = converted.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(converted.valueOf() + timeDiff);
  return adjustedDate.toLocaleDateString();
};

/**
 * Sanitize and parse the html string to react jsx elements
 * @param html The html string
 * @param lines If the parser sees multiple <p> tags, the string will be divided into multiple lines. Set this to only get n first lines
 * @returns
 */
export const sanitizeAndParseHTML = (html: string, lines?: number) => {
  const sanitized = DOMPurify.sanitize(html);
  const parsed = parse(sanitized);
  if (Array.isArray(parsed) && lines) return parsed.slice(0, lines);
  return parsed;
};

export const getAsStringOrDefault = (input:unknown, defaultValue:string) : string => {
  if (typeof input === 'undefined' || input === null) {
    return defaultValue;
  }
  return input.toString();
};

export const urlEncodeBase64 = (input:string) => input.replace(/\+/g, '.').replace(/\//g, '_').replace(/=/g, '-');

export const cvePattern = '([Cc][Vv][Ee]-[0-9]{4}-[0-9]+)';
export const cveStringRegex = new RegExp(`^${cvePattern}$`, 'ig');
const cveRegex = new RegExp(`\\b${cvePattern}`, 'ig');

/**
 * Replace CVS ids with links to CVE database.
 */
export const expandCveLinks = (input:string) => input.replace(
  cveRegex,
  '<a rel="noopener noreferrer" target="_blank" href="https://nvd.nist.gov/vuln/detail/$1">$1</a>',
);

/**
 * Converts ISO date times in the string with local
 */
export const formatDateTimes = (
  input:string,
  formatter?:typeof getStringDate,
  locale?:string,
) => (
  input.replace(
    /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.\d*)?(Z|[-+]\d{2}:\d{2})/g,
    (a, dateString, b, offset) => (formatter ?? getStringDate)(`${dateString}${offset}`, undefined, locale),
  )
);

export const asNameUpn = (user:IUser, escaped?:boolean) => (
  `${user.name ?? 'Name not set'} ${escaped ? '&lt;' : '<'}${user.externalId}${escaped ? '&gt;' : '>'}`
);

const units = ['B', 'KB', 'MB', 'GB', 'TB'];

export const asHumanReadableSize = (bytes: number, decimals?:number): string => {
  if (bytes === 0) return `0 ${units[0]}`;

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = bytes / (1024 ** i);

  const factor = 10 ** (decimals ?? 2);

  return `${Math.round(size * factor) / factor} ${units[i]}`;
};
