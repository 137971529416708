/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { Icon } from '@ailibs/feather-react-ts';
import {
  useReactTable, getCoreRowModel,
  flexRender, getFilteredRowModel, getPaginationRowModel,
  getSortedRowModel, getFacetedRowModel, getFacetedUniqueValues,
  getFacetedMinMaxValues, Row, ExpandedState, getExpandedRowModel, RowData, Updater, SortingState,
  VisibilityState, Header, DisplayColumnDef, ColumnDef, Table, PaginationState,
  Column,
} from '@tanstack/react-table';
import React, {
  HTMLAttributes, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Collapse, Stack, Table as BTable,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { SelectColumnsModalV8 } from './modals/SelectColumnsModalV8';
import { TableStateV8, hasFilterValue } from './TableStoreV8';
import RenderHtml from '../../components/RenderHtml';
import {
  arrIncludesSomeWithEmptyFixFn, fuzzyFilterFn, ReactTableFilterV8, PagedResultTableFilter,
  FilterV8Props,
} from './filters/TableFilter';
import { Direction, ISorting } from './PagedResultFilter';
import { useClickWithoutDrag } from '../useClickWithoutDrag';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string
  }
}

// In case somebody passes a hard coded [], the table would go into an infinite
// rendering loop, as the array would change on each render. Preventing this by
// using a const array to make sure the instance does not change between renders
// https://github.com/TanStack/table/issues/4240
const emptyArray = [] as unknown[];

export interface ICustomFilter<T> {
  disableSorting?:boolean,
  /**
   * If property has a different sort property than in the model, this mapping
   * can be done with this proprery.
   */
  sortPropertyName?: string,
  filterPropertyName: string,
  filterFn?: (values:T[]) => void,
  selectOptions?: T[],
  supportMultiSelect?: boolean,
  valuesFormatterFn?: (values:T[]) => string
}

export type TableColumnDefV8<TObj, TData> = DisplayColumnDef<TObj, TData> & {
  accessorKey?: string,
  disableColumnDefault?: boolean,
  defaultHidden?: boolean,
  className?: string,
  max?: number,
  interval?: number,
  formatter?: (value:TData) => React.ReactNode|string|undefined,
  optionsSortFn?: (itemA:TData, itemB:TData) => number,
}

export type PageableColumnDefV8<TObj, TData> = TableColumnDefV8<TObj, TData> & {
  accessorKey: string,
  customFilter?: ICustomFilter<TData>,
}

/** Adding table to a page / component:
 * Get data with react query (or some other way)
 * Create columns (remember to memoize them) with the columnhelper:
 * `const columnHelper = createColumnHelper<T>();`
 * Then:
 * ```
 * const columns = useMemo<TableColumns<T>>(() => [
 *   columnHelper.accessor('name', {
 *     header: 'Name',
 *   }),
 *   columnHelper.display({
 *     header: 'Actions',
 *     cell: ({ row }) => <button onClick={() => openEdit(row.original.id)}>Edit</button>,
 *   }),
 * ], [columnHelper])
 * ```
 * See https://tanstack.com/table/v8/docs/guide/column-defs for more info
 * Or look at RiskTable etc.
*/

type ReactTableRowClickProps<T> = {
  /** Custom Row Click handler */
  onRowClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: T
  ) => void,
  expand?: never,
  clickRowToToggleExpand?: boolean,
  ExpandElement?: never
}

type ReactTableExpandProps<T> = {
  /** Flag to enable row expand/collapse on click */
  expand?: boolean,
  /** The element to render on row expand */
  ExpandElement?: React.FunctionComponent<{row: Row<T>, even: boolean}>,
  clickRowToToggleExpand?: boolean,
  onRowClick?: never,
}

export type BaseReactTableV8Props<T, TColumnDef> = (ReactTableRowClickProps<T> | ReactTableExpandProps<T>) & {
  /** The table columns. See https://tanstack.com/table/v8/docs/guide/column-defs for more info */
  columnDefs: TColumnDef[], // column type
  /** Flag to disable filters (enabled by default) */
  disableFilters?: boolean,
  /** Flag to disable column selection */
  disableColumnSelect?: boolean,
  // customSelectColumns?: boolean, // ???
  /** Additional props on the table rows */
  rowProps?: React.HTMLAttributes<HTMLTableRowElement>,
  /** Handler for setting props on the row based on row data */
  rowPropSelector?: (row: T) => React.HTMLAttributes<HTMLTableRowElement>,
  rowClassNameResolver?: (row: T) => string|undefined,
  /** Custom toolbar elements to render on the toolbar */
  CustomToolbarElements?: JSX.Element | (JSX.Element | null)[] | null,
  size?: 'sm' | 'lg',
  hover?: boolean,
  refresh?: () => void,
  state:TableStateV8,
  emptyContent?:string|React.ReactNode|undefined,
  className?:string|undefined,
  alternatingRowColors?:boolean,
  renderMarkdown?:boolean,
  noMargin?:boolean,
}

type ReactTableV8Props<T, TColumnDef> = BaseReactTableV8Props<T, TColumnDef> & {
  data: T[],
};

/**
 * Hook for storing the tables expanded state as search params.
 */
const useSearchParamsExpandStatePersister = (paramName:string, pageSize?:number) : [
  ExpandedState,
  (updaterOrValue:Updater<ExpandedState>)=> void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getExpandedFromUri = () => {
    const e = {} as Record<string, boolean>;
    if (pageSize) {
      searchParams.getAll(paramName).forEach((v) => {
      // Expand all items in page if requested
        if (v === 'all') {
          for (let i = 0; i < pageSize; i += 1) {
            e[i] = true;
          }
        } else {
        // Expand single item if requested
          e[v] = true;
        }
      });
    }
    return e as ExpandedState;
  };

  const expanded = getExpandedFromUri();

  const setExpanded = (updaterOrValue: Updater<ExpandedState>) => {
    const value = typeof updaterOrValue === 'function'
      ? updaterOrValue(expanded)
      : updaterOrValue;

    const expandedKeys = Object.keys(value);
    if (!expandedKeys.length) searchParams.delete(paramName);
    else {
      searchParams.set(paramName, expandedKeys[0]);
      expandedKeys.slice(1).forEach((v) => searchParams.append(paramName, v));
    }
    setSearchParams(searchParams, { replace: true });
  };

  return [expanded, setExpanded];
};

const getAccessorKeyFromColumn = <T, >(column:Column<T, unknown>) => {
  const columnDef = column.columnDef as {accessorKey:string|undefined};
  return columnDef.accessorKey;
};

const getAccessorKeyFromHeader = <T, >(header:Header<T, unknown>) => (
  getAccessorKeyFromColumn(header.column)
);

const asReactTableSortingState = (sorting:ISorting[]|null|undefined) : SortingState => (
  sorting
    ? sorting.map((i) => ({ id: i.property, desc: i.direction === Direction.desc }))
    : []
);

const asSorting = (sortingState:SortingState) : ISorting[] => (
  sortingState
    ? sortingState.map((i) => ({ property: i.id, direction: i.desc ? Direction.desc : Direction.asc }))
    : []
);

const TableFilter = <TData, TFilter, >({
  size,
  column,
  table,
  columnDef,
  accessorKey,
  setCustomFilters,
  filterValuesToStringMap,
  filterValuesMap,
}:FilterV8Props<TData> & {
  columnDef: PageableColumnDefV8<TData, TFilter>|undefined,
  accessorKey?: string,
  setCustomFilters?: (accessorKey:string, filter:TData[]) => void,
  filterValuesToStringMap?:Record<string, string>,
  filterValuesMap?:Record<string, TData[]>,
}) => (columnDef && accessorKey && setCustomFilters && filterValuesToStringMap && filterValuesMap
    ? (
      <PagedResultTableFilter
        size={size}
        columnDef={columnDef}
        setFilterValues={(values) => {
          setCustomFilters(accessorKey, values);
        }}
        filterText={filterValuesToStringMap[accessorKey] ?? ''}
        filterValues={filterValuesMap[accessorKey]}
      />
    )
    : (
      <ReactTableFilterV8
        size={size}
        column={column}
        table={table}
      />
    ));

const TableHeader = <T, >({
  sortingState,
  header,
  customFilterDefMap,
  customSorting,
  setCustomSorting,
}:{
  sortingState:SortingState,
  header:Header<T, unknown>,
  customFilterDefMap:Record<string, ICustomFilter<unknown>>,
  customSorting?:ISorting[],
  setCustomSorting?:(sorting:ISorting[]) => void,
}) => {
  const accessorKey = getAccessorKeyFromHeader(header);
  const customFilter = accessorKey ? customFilterDefMap[accessorKey] : null;
  const isPaged = customFilter != null;

  const isSortable = customFilter
    ? !customFilter.disableSorting
    : header.column.getCanSort();

  const sortProperty = customFilter?.sortPropertyName ?? customFilter?.filterPropertyName;

  const columnSort:ISorting|null = useMemo(() => (
    isPaged && customSorting
      ? customSorting.find((s) => (
        s.property === sortProperty
      )) ?? { property: sortProperty } as ISorting
      : null
  ), [sortProperty, isPaged, customSorting]);

  const toggleSort = useCallback((event:unknown) => {
    const toggleFn = columnSort && isPaged && setCustomSorting
      ? () => {
        const sorting = customSorting ? [...customSorting] : [];
        const newColumnSorting:ISorting = {
          ...columnSort,
          direction: (typeof columnSort.direction === 'number' ? (columnSort.direction + 1) % 3 : Direction.asc),
        };
        const idx = sorting.findIndex((s) => s.property === sortProperty);
        if (idx < 0) {
          setCustomSorting([
            newColumnSorting,
          ]);
        } else {
          sorting.splice(idx, 1, newColumnSorting);
          setCustomSorting(sorting);
        }
      }
      : (e:unknown) => {
        const handler = header.column.getToggleSortingHandler();
        if (handler) handler(e);
        if (setCustomSorting) {
          setTimeout(() => {
            setCustomSorting(asSorting(sortingState));
          });
        }
      };
    if (toggleFn) toggleFn(event);
  }, [columnSort, isPaged, setCustomSorting, customSorting, sortProperty, header.column, sortingState]);

  const sortDirection:Direction = isPaged
    ? columnSort?.direction ?? Direction.none
    : header.column.getIsSorted() === 'desc'
      ? Direction.desc
      : header.column.getIsSorted() === 'asc'
        ? Direction.asc
        : Direction.none;

  if (header.isPlaceholder) return null;

  return (
    <Button
      variant="text text-bold"
      disabled={!isSortable}
      onClick={toggleSort}
    >
      {flexRender(
        header.column.columnDef.header,
        header.getContext(),
      )}
      {[
        null,
        <Icon name="arrow-down" className="align-middle" size="16px" />,
        <Icon name="arrow-up" className="align-middle" size="16px" />,
      ][sortDirection] ?? null}
    </Button>
  );
};

export const ReactTableV8 = <T, TFilterValue, TColumnDef extends TableColumnDefV8<T, never>>({
  data,
  onTableInitialized,
  columnDefs,
  disableFilters,
  disableColumnSelect,
  onRowClick,
  rowProps,
  rowPropSelector,
  rowClassNameResolver,
  CustomToolbarElements,
  expand,
  ExpandElement,
  clickRowToToggleExpand,
  size,
  refresh,
  hover,
  state,
  emptyContent,
  className,
  alternatingRowColors,
  renderMarkdown,
  noMargin,
  resetCustomFilters,
  isCustomFiltered,
  customFilterValues,
  setCustomSorting,
  customSorting,
  onReactTablePaginationChanged,
}: ReactTableV8Props<T, TColumnDef> & {
  onTableInitialized?: (initializedTable:Table<T>) => void,
  isCustomFiltered?: boolean,
  resetCustomFilters?: () => Promise<void>|void,
  customSorting?: ISorting[],
  setCustomSorting?: (sorting:ISorting[]) => void,
  customFilterValues?:TFilterValue,
  customFilterDef?:ICustomFilter<TFilterValue>,
  onReactTablePaginationChanged?:(paginationState:Updater<PaginationState>) => void
}) => {
  // Column filters and column visibility is taken from the zustand store
  // This is only used here and used as options for useReactTable
  // All the filters etc. uses table's APIs instead of the zustand store
  const {
    filters: columnFilters,
    setFilters: setColumnFilters,
    globalFilter,
    setGlobalFilter,
    columnVisibility,
    setColumnVisibility,
    pagination,
    setPagination: persistPagination,
  } = state;

  const defaultColumnVisibility = useMemo(() => {
    const mDefaultColumnVisibility:VisibilityState = {};
    columnDefs.forEach((c) => {
      if (!c.accessorKey) {
        return;
      }
      if (c.defaultHidden === true && (c.enableHiding === undefined || c.enableHiding)) {
        mDefaultColumnVisibility[c.accessorKey] = false;
      }
    });
    return mDefaultColumnVisibility;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefs]);

  // Expanded state is not presisted to localstorage, so using a normal React.useState
  const [expanded, setExpanded] = useSearchParamsExpandStatePersister('expand', pagination.pageSize);

  const columnDefMap = useMemo(() => {
    const mColumnDefMap:Record<string, PageableColumnDefV8<T, unknown>> = {};
    columnDefs.forEach((def) => {
      if (def.accessorKey) {
        mColumnDefMap[def.accessorKey] = def as PageableColumnDefV8<T, unknown>;
      }
    });
    return mColumnDefMap;
  }, [columnDefs]);

  const getPageableColumnDef = useCallback((header:Header<T, unknown>) => {
    const accessorKey = getAccessorKeyFromHeader(header);
    return accessorKey && columnDefMap[accessorKey].customFilter
      ? columnDefMap[accessorKey]
      : undefined;
  }, [columnDefMap]);

  const hasActiveFilters = useMemo(() => (
    !!columnFilters.find((f) => f && f.value && (f.value as string).length > 0)
    || isCustomFiltered
    || pagination.pageIndex !== 0
    || (globalFilter?.length ?? 0) > 0
  ), [columnFilters, isCustomFiltered, pagination.pageIndex, globalFilter?.length]);

  const customFilterDefMap = useMemo(() => {
    const filterMap:Record<string, ICustomFilter<unknown>> = {};
    columnDefs.forEach((c) => {
      const pageableDef = c as PageableColumnDefV8<T, unknown>;
      if (pageableDef?.customFilter) {
        filterMap[pageableDef.accessorKey] = pageableDef.customFilter;
      }
    });
    return filterMap;
  }, [columnDefs]);

  const filterValueColumnAccessorKeyMap = useMemo(() => {
    const mFilterValueColumnAccessorKeyMap:Record<string, object[]> = {};
    if (customFilterDefMap && customFilterValues) {
      Object.keys(customFilterDefMap).forEach((accessorKey) => {
        const filterPropertyName = customFilterDefMap[accessorKey]?.filterPropertyName ?? null;
        if (filterPropertyName) {
          const entry = Object.entries(customFilterValues as object).find(([k]) => k === filterPropertyName);
          if (entry) {
            const [, value] = entry;
            mFilterValueColumnAccessorKeyMap[accessorKey] = value as unknown as object[];
          }
        }
      });
    }

    return mFilterValueColumnAccessorKeyMap;
  }, [customFilterDefMap, customFilterValues]);

  const filterValuesToString = useMemo(() => {
    const filterStringMap:Record<string, string> = {};
    columnDefs.forEach((c) => {
      if (!filterValueColumnAccessorKeyMap || !c.accessorKey) return;
      const values = filterValueColumnAccessorKeyMap[c.accessorKey];
      if (!values) return;
      const customFilter = customFilterDefMap[c.accessorKey];

      filterStringMap[c.accessorKey] = Array.isArray(values)
        ? customFilter.valuesFormatterFn
          ? customFilter.valuesFormatterFn(values)
          : (values ?? []).map(String).join(', ')
        : String(values);
    });
    return filterStringMap;
  }, [columnDefs, customFilterDefMap, filterValueColumnAccessorKeyMap]);

  const setCustomFilters = (accessorKey:string, filter:unknown[]) => {
    const filterFn = customFilterDefMap[accessorKey]?.filterFn;
    if (typeof filterFn === 'function') {
      filterFn(filter);
    }
  };

  // Modify stored visibility state to ensure filtered columns are always visible
  const allFilteredVisibleVisibilityState = useMemo(() => {
    const mVisibilityState:VisibilityState = { ...columnVisibility };

    const hiddenFilteredColumns = columnFilters
      .filter((f) => (hasFilterValue(f.value) && columnVisibility[f.id] === false))
      .map((f) => f.id);

    Object.values(columnDefMap).forEach((column) => {
      if (!column.customFilter?.filterPropertyName) return;
      const filterValue = filterValueColumnAccessorKeyMap[column.customFilter?.filterPropertyName];
      if (hasFilterValue(filterValue)
      && column.customFilter
      && !columnVisibility[column.customFilter.filterPropertyName]
      ) {
        hiddenFilteredColumns.push(column.customFilter.filterPropertyName);
      }
    });

    hiddenFilteredColumns.forEach((id) => { mVisibilityState[id] = true; });
    return mVisibilityState;
  }, [columnDefMap, columnFilters, columnVisibility, filterValueColumnAccessorKeyMap]);

  const sorting = useMemo(() => (
    asReactTableSortingState(state.sorting)
  ), [state.sorting]);

  const table = useReactTable({
    data: data.length > 0 ? data : emptyArray as T[],
    columns: columnDefs as ColumnDef<T, unknown>[],
    autoResetPageIndex: !isCustomFiltered,
    filterFns: {
      fuzzy: fuzzyFilterFn,
      arrIncludesSomeWithEmptyFix: arrIncludesSomeWithEmptyFixFn,
    },
    initialState: {
      sorting,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility: allFilteredVisibleVisibilityState,
      expanded,
      pagination: isCustomFiltered
        // When using server side pagination and custom filters table should not do paging.
        // Set pageSize to max value.
        ? { pageIndex: 0, pageSize: Number.MAX_SAFE_INTEGER }
        : pagination,
    },
    onExpandedChange: setExpanded,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: (paginationState:Updater<PaginationState>) => {
      if (!isCustomFiltered) {
        persistPagination(paginationState);
      }
      if (onReactTablePaginationChanged) {
        setTimeout(() => onReactTablePaginationChanged(paginationState));
      }
    },
    globalFilterFn: fuzzyFilterFn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  // Select modal
  const [showSelect, setShowSelect] = useState(false);
  const handleOpenSelect = () => setShowSelect(true);
  const handleCloseSelect = () => setShowSelect(false);

  const onClickWithoutDrag = useClickWithoutDrag();

  const getRowClickProps = (row: Row<T>) => {
    if (onRowClick) {
      return {
        onMouseDown: onClickWithoutDrag.onMouseDown,
        onMouseUp: (
          e:React.MouseEvent<HTMLElement, MouseEvent>,
        ) => onClickWithoutDrag.getMouseUpHandler(e, () => onRowClick(e, row.original)),
        role: 'button',
      };
    }

    return {};
  };

  const getRowExpandProps = (row: Row<T>) : HTMLAttributes<HTMLTableRowElement> => {
    if (expand) {
      return {
        onClick: (e) => {
          const tagName = (e.target as unknown as {tagName:string}).tagName.toLowerCase();
          if (tagName !== 'a' && tagName !== 'button') {
            e.preventDefault();
            row.toggleExpanded();
          }
        },
        role: 'button',
      };
    }

    return {};
  };

  const colSpan = table.getAllLeafColumns().length + (onRowClick ? 1 : 0);

  useEffect(() => {
    if (onTableInitialized) onTableInitialized(table);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  return (
    <div className={`react-table ${noMargin ? '' : 'mb-3'} ${className}`}>
      <BTable hover={hover ?? true} size={size ?? 'sm'} className="table-flex">
        <thead>
          {/* Table header section */}
          {table.getHeaderGroups().map((headerGroup) => {
            // TODO Is there a better way to fix the border? (without having both header and filter in the same th)
            // If they are in the same, the header of action columns (display columns) will be lower than the others
            const atLeastOneFilter = headerGroup.headers.some((h) => h.column.getCanFilter());
            return (
              <React.Fragment key={headerGroup.id}>
                <tr>
                  {headerGroup.headers.map((header) => (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <th key={header.id} colSpan={header.colSpan} className={atLeastOneFilter ? 'pb-0' : ''}>
                      <TableHeader
                        sortingState={table.getState().sorting}
                        header={header}
                        customFilterDefMap={customFilterDefMap}
                        customSorting={customSorting}
                        setCustomSorting={setCustomSorting}
                      />
                    </th>
                  ))}
                  { onRowClick ? <th /> : null }
                </tr>
                {!disableFilters ? (
                  <tr className="filter-row">
                    {headerGroup.headers.map((header) => (atLeastOneFilter ? (
                      <th key={header.id}>
                        {!disableFilters
                          ? (
                            <TableFilter
                              size={size}
                              column={header.column}
                              table={table}
                              accessorKey={getAccessorKeyFromHeader(header)}
                              columnDef={getPageableColumnDef(header)}
                              setCustomFilters={setCustomFilters}
                              filterValuesToStringMap={filterValuesToString}
                              filterValuesMap={filterValueColumnAccessorKeyMap as Record<string, T[]>}
                            />
                          ) : null }
                      </th>
                    ) : null))}
                    { onRowClick ? <th /> : null }
                  </tr>

                ) : null}
              </React.Fragment>
            );
          })}
          {/* Table Toolbar (in table head) Section */}
          {!disableFilters || !disableColumnSelect || CustomToolbarElements ? (
            <tr key="filters" className="filter-toolbar">
              <th
                colSpan={colSpan}
              >
                <div>
                  <span className="float-end">
                    <Stack direction="horizontal" gap={1}>
                      {CustomToolbarElements}
                      {refresh ? (
                        <Button
                          variant="secondary"
                          size={size ?? 'sm'}
                          onClick={refresh}
                        >
                          Refresh
                          {' '}
                          <Icon name="refresh-cw" size="12px" />
                        </Button>
                      ) : null }
                      {disableColumnSelect
                        ? null
                        : (
                          <Button
                            variant="secondary"
                            size={size ?? 'sm'}
                            onClick={handleOpenSelect}
                          >
                            Visible columns
                            {' '}
                            <Icon name="sidebar" size="12px" />
                          </Button>
                        )}
                      {!disableFilters ? (
                        <Button
                          variant="secondary"
                          className={hasActiveFilters ? 'has-selection' : ''}
                          size={size ?? 'sm'}
                          disabled={!hasActiveFilters}
                          onClick={async () => {
                            table.resetGlobalFilter();
                            table.resetColumnFilters();
                            if (typeof resetCustomFilters === 'function') {
                              await resetCustomFilters();
                            }
                          }}
                        >
                          Clear filters
                          {' '}
                          <Icon name="x-square" size="12px" />
                        </Button>
                      ) : null}
                    </Stack>
                  </span>
                </div>
              </th>
            </tr>
          ) : null}
        </thead>
        {/* Table body section */}
        <tbody>
          { table.getRowModel().rows.length === 0
            ? (
              <tr className="no-content-row">
                <td colSpan={table.getAllColumns().length}>
                  { emptyContent ?? <div className="p-1 text-muted">No content...</div> }
                </td>
              </tr>
            )
            : table.getRowModel().rows.map((row, rowIdx) => {
              const classNames = [] as string[];
              if (expand) classNames.push('border-bottom-0 head-row');

              const even = rowIdx % 2 === 0;

              const rowClassNames = [
                ...classNames,
                rowClassNameResolver ? rowClassNameResolver(row.original) : '',
              ];

              if (alternatingRowColors !== false) {
                rowClassNames.push(even ? 'even' : 'odd');
              }

              if (expand && ExpandElement && row.getIsExpanded()) {
                rowClassNames.push('expanded');
              }

              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...getRowClickProps(row)}
                    {...(rowProps ?? {})}
                    {...rowPropSelector && rowPropSelector(row.original)}
                    {...expand && (clickRowToToggleExpand ?? true) && getRowExpandProps(row)}
                    className={rowClassNames.join(' ')}
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellClassNames = [
                        ...classNames,
                      ];
                      if (cell.column.columnDef.meta?.className) {
                        cellClassNames.push(cell.column.columnDef.meta.className);
                      }
                      return (
                        <td
                          key={cell.id}
                          className={`${cellClassNames.join(' ')}`}
                        >
                          { renderMarkdown && typeof cell.getContext().renderValue() === 'string'
                            ? (
                              <RenderHtml allowedTags={['br', 'a']} allowedAttributes={['target']}>
                                {cell.getContext().renderValue() as string}
                              </RenderHtml>
                            )
                            : flexRender(cell.column.columnDef.cell, cell.getContext()) }
                        </td>
                      );
                    })}
                    { onRowClick
                      ? (
                        <td className="text-end">
                          <OverlayTrigger
                            overlay={<Tooltip>Open</Tooltip>}
                          >
                            <Button variant="link" onClick={(e) => { onRowClick(e, row.original); }}>
                              <Icon name="external-link" size="18" />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      )
                      : null }
                  </tr>
                  {expand && ExpandElement && row.getIsExpanded() ? (
                    // eslint-disable-next-line no-nested-ternary
                    <tr className={`expand-row${row.getIsExpanded() ? ' expanded' : ''} ${alternatingRowColors ? (even ? 'even' : 'odd') : ''}`}>
                      <td colSpan={colSpan} className="p-0 border-top-0">
                        <Collapse className="p-2" in={row.getIsExpanded()}>
                          <div>
                            <ExpandElement row={row} even={even} />
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
        </tbody>
      </BTable>
      <SelectColumnsModalV8
        show={showSelect}
        handleClose={handleCloseSelect}
        table={table}
        defaultColumnVisibility={defaultColumnVisibility}
        filterValueColumnAccessorKeyMap={filterValueColumnAccessorKeyMap}
        getAccessorKeyFromColumn={getAccessorKeyFromColumn}
      />
    </div>
  );
};

export default ReactTableV8;
