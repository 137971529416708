import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../routing/Routes';
import SidebarItem from './SidebarItem';
import { Module } from '../types/AccessTypes';
import { useAccount } from '../providers/AccountProvider';
import { useAvailableCustomerData } from '../providers/AvailableCustomerDataProvider';

interface SidebarProps {
  collapsed: boolean,
}

/**
 * The sidebar for the webpage, automatically included in all pages
 *
 * New pages need to manually be included, use SidebarItem for a normal page/route.
 * 1. Add route to the global ROUTES object, with the icon, header title and sidebar name
 * 2. Add a new SidebarItem here
 * 3. Add to App.jsx to the routes to make the url route to the new page
 *
 * SidebarItem also supports subitems, SidebarItem.SubItem which accepts a route.
 * SidebarItem here needs activePath (/azure for all /azure/something pages), icon and label
 * Sub routes needs to start with activePath, but never be equal to it
 * or the sidebar will not show the correct active page
 *
 * Sidebar is scrollable if page is too short, but shows no visible scrollbar (react-bootstrap / adminkit issues?)
 */
export const Sidebar = ({ collapsed }: SidebarProps) => {
  const { hasModuleRole, hasAnyModuleRole } = useAccount();
  const availableData = useAvailableCustomerData();
  const navigate = useNavigate();

  return (
    <nav id="sidebar" className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <Link className="sidebar-brand" to={ROUTES.dashboard.uri}>Ivolv Cybersecurity</Link>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="sidebar-content-wrapper" tabIndex={0} role="region" aria-label="scrollable content">
          <SidebarItem route={ROUTES.dashboard} />
          { availableData.hasRisks && hasModuleRole(Module.risk, 'read')
            ? (
              <SidebarItem route={ROUTES.risk} />
            )
            : null}
          { availableData.hasVulnerabilities && hasModuleRole(Module.vulnerability, 'read')
            ? (
              <SidebarItem
                activePath={ROUTES.vulnerabilities.uri}
                icon={ROUTES.vulnerabilities.icon}
                label={ROUTES.vulnerabilities.sidebar}
                onOpenClick={() => {
                  navigate(ROUTES.vulnerabilities.uri);
                }}
              >
                { /* <SidebarItem.SubItem route={ROUTES.vulnerabilitySummary} /> */ }
                { /* <SidebarItem.SubItem route={ROUTES.vulnerabilitiesByControl} /> */ }
                <SidebarItem.SubItem route={ROUTES.vulnerabilities} />
                { availableData.hasVulnerabilityTrends
                  ? <SidebarItem.SubItem route={ROUTES.vulnerabilityHistory} />
                  : null }
                { hasModuleRole(Module.reporting, 'read')
                  ? (
                    <SidebarItem.DownloadItem
                      label="Vulnerability report"
                      uri="reports/vulnerabilities"
                    />
                  ) : null}
              </SidebarItem>
            )
            : null}
          { availableData.hasAssets && hasModuleRole(Module.assets, 'read')
            ? (
              <SidebarItem route={ROUTES.assets} />
            )
            : null}
          { hasModuleRole(Module.admin, 'read') ? (
            <SidebarItem
              activePath={ROUTES.admin.uri}
              icon={ROUTES.admin.icon}
              label={ROUTES.admin.sidebar}
            >
              <SidebarItem.SubItem route={ROUTES.adminAssessments} />
              <SidebarItem.SubItem route={ROUTES.adminConsents} />
              <SidebarItem.SubItem route={ROUTES.adminCustomers} />
              <SidebarItem.SubItem route={ROUTES.adminJobs} />
              { hasModuleRole(Module.admin, 'admin')
                ? <SidebarItem.SubItem route={ROUTES.adminModules} />
                : null }
              <SidebarItem.SubItem route={ROUTES.adminTools} />
              { hasModuleRole(Module.admin, 'readWrite')
                ? <SidebarItem.SubItem route={ROUTES.adminCves} />
                : null }
            </SidebarItem>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
