import React, { useRef } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { AssetsTable } from './AssetsTable';
import { IAsset } from '../../types/AssetsTypes';
import { PagedResult } from '../../types/PagedResult';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { ElementScrollRestoration } from '../../routing/ElementScrollRestoration';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ValidPageSizes } from '../../common/table/PaginationV8';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IAssetListOptions } from '../../types/Types';

export const AssetsPage:IComponentWithLoader<PagedResult<IAsset>, never> = {
  loader: (queryClient:QueryClient, _, pageSize:ValidPageSizes): Promise<PagedResult<IAsset>> => (
    getOrFetchFromApi<PagedResult<IAsset>>(
      queryClient,
      'assets',
      {
        pageSize,
      },
    )
  ),
  Component: () => {
    const pagedTableFilter = usePagedTableFilter<IAssetListOptions>(
      'assets-page',
      {},
      {},
      [
        { property: 'name', direction: Direction.asc },
      ],
    );

    const { pageableQuery } = pagedTableFilter;

    const { data: pagedAssets } = useApiLoaderData<PagedResult<IAsset>, PagedResult<IAsset>>(
      'assets',
      (loaderData) => loaderData,
      pageableQuery,
    );

    const scrollContainerRef = useRef(null);

    return (
      <div>
        <Row>
          <Col>
            <Card className="fill-content">
              <Card.Header>
                <Card.Title>Assets</Card.Title>
              </Card.Header>
              <Card.Body ref={scrollContainerRef} className="overflow-auto" id="assets-card">
                <AssetsTable pagedAssets={pagedAssets} pagedQuery={pagedTableFilter} />
              </Card.Body>
            </Card>
            <ElementScrollRestoration targetId="assets-card" />
          </Col>
        </Row>
      </div>
    );
  },
};

export default AssetsPage;
